import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {
  CssBaseline, AppBar, Toolbar, Hidden,
} from '@material-ui/core';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import theme from './theme';
import { Teacher, QRViewer } from './pages/teacher';
import Student from './pages/student';
import Footer from './components/footer';
import PageContainer from './components/page-container';
import Participation from './pages/register-participation';
import RegisterAbsence from './pages/register-absence';
import AbsenceOverview from './pages/absence-overview';
import Landing from './pages/landing';
import logo from './images/Horisontal standard.svg';
import logoSmall from './images/logo.svg';

const wrapPage = Component => () => (
  <PageContainer>
    <Component />
  </PageContainer>
);

function App({ classes }) {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static" color="inherit" className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.image}>
              <Link to="/">
                <Hidden xsDown>
                  <img src={logo} className={classes.logo} alt="logo" />
                </Hidden>
                <Hidden smUp>
                  <img src={logoSmall} className={classes.logo} alt="logo" />
                </Hidden>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <Route exact path="/" component={wrapPage(Landing)} />
        <Route exact path="/l" component={wrapPage(Teacher)} />
        <Route exact path="/l/qr-:activityId-:name" component={wrapPage(QRViewer)} />
        <Route exact path="/e" component={wrapPage(Student)} />
        <Route exact path="/e/register-participation" component={wrapPage(Participation)} />
        <Route exact path="/e/register-absence" component={wrapPage(RegisterAbsence)} />
        <Route exact path="/e/absence-overview" component={wrapPage(AbsenceOverview)} />
        <Route exact path="/e/register-participation/:code" component={wrapPage(Participation)} />
        <Route exact path="/e/register-absence/:code" component={wrapPage(RegisterAbsence)} />
        <Footer />
      </MuiThemeProvider>
    </Router>
  );
}

const styles = () => ({
  logo: {
    [theme.breakpoints.up('sm')]: {
      height: '5.4rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '4rem',
    },
  },
  appbar: {
    textAlign: '-webkit-center',
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1.25rem solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('xs')]: {
      borderBottom: `0.8rem solid ${theme.palette.primary.main}`,
    },
  },
  toolbar: {
    maxWidth: '78rem',
    padding: '0',
  },
  image: {
    padding: '0px 10px',
  },
});

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
