import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Work, WorkOff, ListAlt } from '@material-ui/icons';
import { Translate } from 'react-localize-redux';
import Card from '../../components/card';

const Landing = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Typography variant="h4"><Translate id="student.landing.title" /></Typography>
      </Grid>
      <Grid item xs={12} md={9} styles={{ flexGrow: 0 }}>
        <Card
          title={<Translate id="student.landing.participation.title" />}
          href="/e/register-participation"
          icon={Work}
        />
      </Grid>
      <Grid item xs={12} md={9} styles={{ flexGrow: 0 }}>
        <Card
          title={<Translate id="student.landing.absence.title" />}
          href="/e/register-absence"
          icon={WorkOff}
        />
      </Grid>
      <Grid item xs={12} md={9} styles={{ flexGrow: 0 }}>
        <Card
          title={<Translate id="student.landing.viewAbsence.title" />}
          href="/e/absence-overview"
          icon={ListAlt}
        />
      </Grid>
    </Grid>
  </div>
);

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  root: {

  },
});

export default withStyles(styles)(Landing);
