import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Typography,
} from '@material-ui/core';
// TODO: fix getActiveLanguage usage
// eslint-disable-next-line no-unused-vars
import { Translate, getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Autorenew, ErrorOutline, CheckCircleOutline } from '@material-ui/icons';
import { Fetch } from '../services/fetch-service';
import RegisterAbsenceDialog from './register-absence-dialog';
import Textbox from './text-box';


class RegistrationTemplate extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          absencecode, from, to,
        },
      },
    } = props;

    this.state = {
      success: false,
      fetching: false,
      fetched: false,
      absencecode: absencecode || '0',
      from: from || new Date(),
      to: to || new Date(),
      openDialog: !absencecode,

    };

    if (absencecode && from && to) {
      this.state.fetching = true;
      this.register();
    }
  }

  handleRadioChange = (val) => {
    this.setState({ absencecode: val });
  }

  handleDateChange = (valueName, value) => {
    this.setState({ [valueName]: value });
  }

  handleClose = () => {
    const { absencecode, from, to } = this.state;
    if (absencecode && from && to) {
      this.setState({ openDialog: false, fetching: true });
      this.register();
    }
  }

  register = () => {
    const { absencecode, from, to } = this.state;
    const jsonBody = { absenceCode: absencecode, start: from, end: to };


    // Fetch(`/flyvo-auth/event/${registrationType}?participationId=${code}`)
    Fetch('/flyvo-auth/absence/registerSickLeave', {
      method: 'POST',
      body: JSON.stringify(jsonBody),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
      })
      .then(() => this.setState({ success: true }))
      .catch(() => this.setState({ success: false }))
      .finally(() => this.setState({ fetched: true, fetching: false }));
  }

  render() {
    const {
      classes, activeLanguage, registrationType, history,
    } = this.props;
    const {
      success, fetching, fetched, openDialog, absencecode, from, to,
    } = this.state;
    return (
      <div className={classes.root}>
        <RegisterAbsenceDialog
          titleId={`student.${registrationType}.dialog.title`}
          textId={`student.${registrationType}.dialog.text`}
          open={openDialog}
          handleClose={this.handleClose}
          handleRadioChange={this.handleRadioChange}
          selectValue={absencecode}
          classes={classes}
          handleDateChange={this.handleDateChange}
          from={from}
          to={to}
          activeLanguage={activeLanguage}
        />

        {!openDialog && (
          <Typography variant="h4" className={classes.header}><Translate id={`student.${registrationType}.dialog.title`} /></Typography>
        )}

        {!openDialog && (fetching ? (
          <Textbox><Skeleton height="1rem" /></Textbox>
        ) : (
          <>
            {fetched && (
              <Textbox title={(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {success ? [
                    <CheckCircleOutline key="icon" style={{ marginRight: '1rem', color: 'green' }} />,
                    <Translate key="text" id={`student.${registrationType}.fetchSuccessTitle`} />,
                  ] : [
                    <ErrorOutline key="icon" color="error" style={{ marginRight: '1rem' }} />,
                    <Translate key="text" id={`student.${registrationType}.fetchErrorTitle`} />,
                  ]}
                </div>
              )}
              >
                {success ? (
                  <Typography variant="body1"><Translate id={`student.${registrationType}.success`} /></Typography>
                ) : [
                  <Typography variant="body1" key="text">
                    <Translate id={`student.${registrationType}.fetchError`} />
                  </Typography>,
                  <div className={classes.retrybtn} key="btn">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        this.setState({ fetching: true, openDialog: true });
                        // this.register();
                      }}
                    >
                      <Translate id="core.retry" />
                      <Autorenew />
                    </Button>

                  </div>,
                ]}
              </Textbox>
            )}
          </>
        ))}
        {!openDialog && (
          <Button
            variant="outlined"
            className={classes.backbtn}
            color="primary"
            onClick={() => {
              const path = history.location.pathname.split('/');
              const s = path.slice(0, path.length - 1).join('/');
              history.replace(s);
            }}
          >
            <ArrowBack />
            <Translate id="core.back" />
          </Button>
        )}
      </div>
    );
  }
}

RegistrationTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  activeLanguage: PropTypes.string.isRequired,
  registrationType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
const styles = () => ({
  backbtn: {
    marginTop: '3rem',
  },
  dateRangePickerLabel: {
    margin: '0.5rem',
  },
  drpContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  drp: {
    flexGrow: '1',
    marginBottom: '0.5rem',
  },
  formControl: {
    width: '100%',
  },
  header: {
    marginBottom: '1rem',
  },
  retrybtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '2rem',
  },
});

const mapStateToProps = ({
  localize,
}) => ({
  translate: getTranslate(localize),
  activeLanguage: 'nb', // getActiveLanguage(localize).code, // TODO FIXME
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withRouter,
)(RegistrationTemplate);
