import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TableRow from '@material-ui/core/TableRow/TableRow';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// TODO: @dina refactor til at man oppretter tableCell-objektene selv.
// Så man her bare henter de gjennom props.children
// Se breadcrumb-bar for eksempel på dette
class CustomTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  handleClickLink = (link) => {
    if (link) {
      const { history, history: { location: { pathname: path } } } = this.props;
      history.push(`${path}/${link}`);
    }
  };

  enterSpaceKeyDown = action => (e) => {
    if (e.which === 32 || e.which === 13) {
      action(e);
      e.preventDefault();
    }
  }

  render() {
    const { focused } = this.state;

    const {
      classes, link, onClick, children, odd, href, isFocused,
    } = this.props;

    const c = clsx(
      classes.row,
      (link || onClick || href) && [classes.clickable, classes.hovering],
      odd && classes.odd,
      isFocused && classes.manualFocus,
    );

    const adtProps = {};
    if (link) {
      adtProps.onKeyDown = this.enterSpaceKeyDown(() => this.handleClickLink(link));
      adtProps.onClick = () => this.handleClickLink(link);
    } else if (onClick) {
      adtProps.onKeyDown = this.enterSpaceKeyDown(e => onClick(e));
      adtProps.onClick = onClick;
    } else if (href) {
      adtProps.onClick = () => { window.open(href, '_blank').opener = null; };
    }

    if (link || onClick) {
      adtProps.tabIndex = 0;
    }

    return (
      <TableRow
        onFocus={() => this.setState({ focused: true })}
        onBlur={() => this.setState({ focused: false })}
        className={c}
        hover={!!onClick || !!link || !!href || focused || isFocused}
        selected={focused || isFocused}
        {...adtProps}
      >
        {children}
      </TableRow>
    );
  }
}

CustomTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  link: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.array.isRequired,
  odd: PropTypes.bool,
  isFocused: PropTypes.bool,
};

CustomTableRow.defaultProps = {
  onClick: undefined,
  link: '',
  odd: false,
  href: '',
  isFocused: false,
};

const styles = theme => ({
  clickable: {
    cursor: 'pointer',
  },
  href: {
    padding: 'auto',
  },
  row: {
    borderLeft: '0.2rem solid white',
    '&:link, &:visited': {
      textDecoration: 'inherit',
    },
  },
  hovering: {
    '&:hover, &:focus': {
      borderLeft: '0.2rem solid',
      borderColor: theme.palette.primary.main,
    },
  },
  manualFocus: {
    borderLeft: '0.2rem solid',
    borderColor: theme.palette.primary.main,
  },
  odd: {
    backgroundColor: '#f7f7f7',
  },
});

export default withRouter(withStyles(styles)(CustomTableRow));
