import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import LockIcon from '@material-ui/icons/Lock';
import LanguageSelector from './language-selector';
import { Fetch } from '../services/fetch-service';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    Fetch('/flyvo-auth/aboutMe')
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        this.setState({
          email: res.email,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { email } = this.state;
    return (
      <div>
        <div className={classes.container}>
          <div className={classes.body}>
            <div className={classes.content}>
              <div>
                <LanguageSelector />
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.btncontainer}>
                  {/* <Hidden smUp> */}
                  <Typography color="primary" className={classes.mail}>{email}</Typography>
                  {/* </Hidden> */}
                  <Button
                    onClick={() => {
                      Fetch('/flyvo-auth/logout').then((res) => {
                        if (!res.ok) {
                          throw res;
                        }
                        window.location = 'https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://opp.trovo.no/#/';
                      });
                    }}
                    color="primary"
                    variant="outlined"
                  >
                    <LockIcon />
                    <Translate id="footer.logout" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    marginTop: '5rem',
    borderTop: '1px solid lightgray',
  },
  content: {
    padding: '1rem 1.6rem',
    maxWidth: '68.5rem',
    margin: 'auto',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  btncontainer: {
    [theme.breakpoints.up('sm')]: {
      float: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
  body: {
    backgroundColor: theme.palette.secondary.light,
    height: '6rem',
  },
  mail: {
    maxWidth: '11rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(Footer);
