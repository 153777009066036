import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router-dom';
import {
  DateRangePicker,
} from '@tktip/components';
import SvgIcon from '@material-ui/core/SvgIcon';
import Accessibility from '@material-ui/icons/Accessibility';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';

const CodeEntryDialog = ({
  titleId,
  textId,
  handleClose,
  handleRadioChange,
  selectValue,
  classes,
  from,
  to,
  activeLanguage,
  handleDateChange,
  open,
  history,
}) => (
  <div>
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      aria-labelledby="form-dialog-title"
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          handleClose();
        }
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Translate id={titleId} />
      </DialogTitle>
      <DialogContent>
        <FormLabel component="legend"><Translate id="registerAbsence.default" /></FormLabel>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          htmlFor="Absencetype"
          label={<Typography style={{ display: 'none' }}><Translate id="registerAbsence.default" /></Typography>}
          control={(
            <FormControl id="Absencetype" className={classes.buttoncontainer}>
              <Button type="radio" style={{ marginTop: '0.5rem' }} value="0" color="primary" variant={selectValue === '0' ? 'contained' : 'outlined'} onClick={() => handleRadioChange('0')}>
                <Accessibility />
                <Translate id="registerAbsence.selfCertification" />
              </Button>
              <Button type="radio" style={{ marginTop: '0.5rem' }} value="1" color="primary" variant={selectValue === '1' ? 'contained' : 'outlined'} onClick={() => handleRadioChange('1')}>
                <SvgIcon>
                  <path d="M6.5,2c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S5.4,2,6.5,2z M15.5,9.5c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5 S17.83,8,17,8S15.5,8.67,15.5,9.5z M18.5,12h-2.84c-0.58,0.01-1.14,0.32-1.45,0.86l-0.92,1.32L9.72,8C9.35,7.37,8.69,7.01,8.01,7H5 C3.9,7,3,7.9,3,9v6h1.5v7h5V11.61L12.03,16h2.2L15,14.9V22h4v-5h1v-3.5C20,12.68,19.33,12,18.5,12z" />
                </SvgIcon>
                <Translate id="registerAbsence.selfCertificationChildren" />
              </Button>
            </FormControl>
          )}
        />
        <div style={{ marginTop: '1.5rem' }}>
          <DialogContentText>
            <Translate id={textId} />
          </DialogContentText>
          <DateRangePicker
            className={classes.drp}
            onChange={(valueName, value) => {
              if (valueName === 'from' && value > to) {
                handleDateChange('to', value);
              }
              handleDateChange(valueName, value);
            }}
            toProps={{
              minDate: from,
            }}
            from={from}
            to={to}
            fromLabel={<Translate id="student.registerAbsence.datepicker.fromDate" />}
            toLabel={<Translate id="student.registerAbsence.datepicker.toDate" />}
            submitLabel={<Translate id="student.registerAbsence.datepicker.label" />}
            doneLabel="OK"
            locale={activeLanguage}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            const path = history.location.pathname.split('/');
            const s = path.slice(0, path.length - 1).join('/');
            history.replace(s);
          }}
        >
          <Translate id="core.cancel" />
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

CodeEntryDialog.propTypes = {
  titleId: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  selectValue: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  from: PropTypes.any.isRequired,
  to: PropTypes.any.isRequired,
  activeLanguage: PropTypes.any.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CodeEntryDialog);
