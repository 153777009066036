import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Typography, Paper, Dialog, DialogTitle, DialogContent,
  DialogActions, Button, Hidden, List, ListItem, ListItemText, Divider,
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import {
  DateRangePicker, DateString, getDateTime, addWeeks,
} from '@tktip/components';
import { Translate, getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Fetch } from '../../services/fetch-service';
import TKTableRow from '../../components/table-row';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      fetched: false,
      fetchError: undefined,
      from: new Date(),
      to: new Date(),
      order: 'asc',
      orderBy: 'dateFrom',
      openDialog: {},
    };
    this.fetchActivities();
  }

  resetAndFetchActivities = (e, dateFrom, dateTo) => {
    this.setState({ fetched: false, fetchError: undefined, activities: [] });
    this.fetchActivities(e, dateFrom, dateTo);
  }

  mapDate = (date, time) => {
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4, 8);
    const hours = time.substring(0, 2);
    const minutes = time.substring(3, 5);
    return new Date(year, (month - 1), day, hours, minutes);
  }

  fetchActivities = (e, dateFrom, dateTo) => {
    const { from, to } = this.state;

    Fetch(`/flyvo-auth/event/retrieve/${getDateTime((dateFrom || from), 'P', 'nb')}/${getDateTime((dateTo || to), 'P', 'nb')}`)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        for (let i = 0; i < res.length; i += 1) {
          const dateFromMapped = this.mapDate(res[i].date, res[i].timeFrom);
          const dateToMapped = this.mapDate(res[i].date, res[i].timeTo);
          res[i].dateFrom = dateFromMapped;
          res[i].dateTo = dateToMapped;
        }
        this.setState({ activities: res, fetched: true });
      })
      .catch(() => this.setState({ fetchError: true, fetched: true }));
  }

  createSortHandler = property => () => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  render() {
    const {
      classes, activeLanguage: { code: activeLanguage }, translate, history,
    } = this.props;
    const {
      activities, fetched, fetchError, from, to, order, orderBy, openDialog,
    } = this.state;

    const rows = activities && stableSort(activities, getComparator(order, orderBy));

    return (
      <div className={classes.root}>
        <div className={classes.drpContainer}>
          <Typography variant="body1" className={clsx(classes.label, classes.mt)}>
            <Translate id="teacher.activities.dateRange.label" />
          </Typography>
          <DateRangePicker
            className={classes.drp}
            onChange={(valueName, value) => {
              if (valueName === 'from' && value > to) {
                this.setState({
                  to: value,
                });
              }
              this.setState({
                [valueName]: value,
              });
            }}
            from={from}
            to={to}
            onSearch={this.resetAndFetchActivities}
            fromLabel={<Translate id="teacher.activities.dateRange.fromDate" />}
            fromProps={{
              disablePast: true,
              maxDate: addWeeks(new Date(), 2),
            }}
            toLabel={<Translate id="teacher.activities.dateRange.toDate" />}
            toProps={{
              disablePast: true,
              minDate: from,
              maxDate: addWeeks(new Date(), 2),
            }}
            submitLabel={translate('teacher.activities.dateRange.search')}
            cancelLabel={translate('teacher.activities.dateRange.cancel')}
            locale={activeLanguage}
            quickButs={[
              {
                dateTo: new Date(),
                label: translate('teacher.activities.dateRange.today'),
              },
              {
                dateTo: addWeeks(new Date(), 1),
                label: translate('teacher.activities.dateRange.nextWeek'),
              },
              {
                dateTo: addWeeks(new Date(), 2),
                label: translate('teacher.activities.dateRange.next2Weeks'),
              },
            ]}
          />
        </div>
        {!fetched ? (
          <Paper elevation={0} className={classes.tablecontainer} style={{ padding: '0.5rem' }}>
            <Skeleton count={3} height="1.5rem" />
          </Paper>
        ) : (
          <div>
            {!fetchError ? (
              <Paper elevation={0} className={classes.tablecontainer}>
                <Hidden xsDown>
                  <Table
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        {[{ id: 'activity', sort: 'name' },
                          { id: 'date', sort: 'dateFrom' },
                          { id: 'time', sort: 'timeFrom' }]
                          .map(item => (
                            <TableCell key={item.id} className={classes.tableheader}>
                              <TableSortLabel
                                active={orderBy === item.sort}
                                direction={orderBy === item.sort ? order : 'asc'}
                                onClick={this.createSortHandler(item.sort)}
                              >
                                <Translate id={`teacher.activities.columns.${item.id}`} />
                                {orderBy !== item.sort && <UnfoldMore fontSize="small" />}
                              </TableSortLabel>

                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.length ? (
                        rows.map((activity, index) => [
                          <TKTableRow
                            onClick={() => (
                              this.setState({
                                openDialog: { ...openDialog, [activity.vismaActivityId]: true },
                              })
                            )}
                            odd={!(index % 2)}
                            key={activity.vismaActivityId}
                          >
                            <TableCell component="th" scope="row">
                              {activity.name}
                            </TableCell>
                            <TableCell><DateString dateTime={activity.dateFrom} /></TableCell>
                            <TableCell>{`${getDateTime(activity.dateFrom, 'p', activeLanguage)}-${getDateTime(activity.dateTo, 'p', activeLanguage)}`}</TableCell>
                          </TKTableRow>,
                          <Dialog
                            key={`dialog--${activity.vismaActivityId}`}
                            open={!!openDialog[activity.vismaActivityId]}
                            maxWidth="sm"
                            fullWidth
                          >
                            <DialogTitle>
                              <Translate id="teacher.activities.generatecode" />
                              <i>{activity.name}</i>
                              ?
                            </DialogTitle>
                            {new Date(activity.dateFrom).setHours(0, 0, 0, 0)
                              !== new Date().setHours(0, 0, 0, 0) && (
                              <DialogContent>
                                <Typography variant="body1"><Translate id="teacher.activities.codeinfo" /></Typography>
                              </DialogContent>
                            )}
                            <DialogActions>
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => this.setState({
                                  openDialog: { ...openDialog, [activity.vismaActivityId]: false },
                                })}
                              >
                                <Translate id="teacher.activities.cancel" />
                              </Button>

                              <Button
                                color="primary"
                                variant="contained"
                                disabled={new Date(activity.dateFrom).setHours(0, 0, 0, 0)
                                  !== new Date().setHours(0, 0, 0, 0)}
                                onClick={() => history.push(`l/qr-${activity.vismaActivityId}-${(encodeURIComponent(activity.name))}`)}
                              >
                                <Translate id="teacher.activities.generate" />
                              </Button>

                            </DialogActions>
                          </Dialog>,
                        ])
                      ) : (
                        <TKTableRow>
                          <TableCell component="th" scope="row" colSpan={3}>
                            <Translate id="teacher.activities.noActivitiesFound" />
                          </TableCell>
                        </TKTableRow>
                      )}
                    </TableBody>
                  </Table>
                </Hidden>
                <Hidden smUp>
                  <List style={{ marginTop: '0.5rem' }}>
                    <Divider />
                    {rows.length ? (
                      rows.map((activity, index) => [
                        <ListItem
                          onClick={() => this.setState({
                            openDialog: { ...openDialog, [activity.vismaActivityId]: true },
                          })}
                          divider
                          key={activity.vismaActivityId}
                          className={!(index % 2) ? classes.odd : ''}
                        >
                          <ListItemText
                            primary={activity.name}
                            secondary={(
                              <>
                                <DateString dateTime={activity.dateFrom} />
                                {` kl: ${getDateTime(activity.dateFrom, 'p', activeLanguage)}-${getDateTime(activity.dateTo, 'p', activeLanguage)}`}
                              </>
                            )}
                          />
                        </ListItem>,
                        <Dialog
                          key={`dialog--${activity.vismaActivityId}`}
                          open={!!openDialog[activity.vismaActivityId]}
                          maxWidth="sm"
                          fullWidth
                        >
                          <DialogTitle>
                            <Translate id="teacher.activities.generatecode" />
                            <i>{activity.name}</i>
                              ?
                          </DialogTitle>
                          {new Date(activity.dateFrom).setHours(0, 0, 0, 0)
                            !== new Date().setHours(0, 0, 0, 0) && (
                            <DialogContent>
                              <Typography variant="body1"><Translate id="teacher.activities.codeinfo" /></Typography>
                            </DialogContent>
                          )}
                          <DialogActions>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => this.setState({
                                openDialog: { ...openDialog, [activity.vismaActivityId]: false },
                              })}
                            >
                              <Translate id="teacher.activities.cancel" />
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={new Date(activity.dateFrom).setHours(0, 0, 0, 0)
                                !== new Date().setHours(0, 0, 0, 0)}
                              onClick={() => history.push(`l/qr-${activity.vismaActivityId}-${(encodeURIComponent(activity.name))}`)}
                            >
                              <Translate id="teacher.activities.generate" />
                            </Button>
                          </DialogActions>
                        </Dialog>,
                      ])
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary={<Translate id="teacher.activities.noActivitiesFound" />}
                        />
                      </ListItem>
                    )}
                  </List>
                </Hidden>
              </Paper>
            ) : (
              <Typography variant="body1">
                <Translate id="teacher.activities.fetchError" />
              </Typography>
            )}
          </div>
        )}
      </div>
    );
  }
}

Activities.propTypes = {
  classes: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

Activities.defaultProps = {
  activeLanguage: { },
};

const styles = () => ({
  dateRangePickerLabel: {
    margin: '0.5rem',
  },
  label: {
    marginBottom: '0.4rem',
  },
  mt: {
    marginTop: '1rem',
  },
  drp: {
    flexGrow: '1',
    marginBottom: '1.5rem',
  },
  tableheader: {
    fontSize: '1rem',
    color: 'black',
  },
  tablecontainer: {
    padding: '0 0.5rem 0.5rem 0.5rem',
    border: '1px solid lightgray',
  },
  odd: {
    backgroundColor: '#f7f7f7',
  },
});

const mapStateToProps = ({
  localize,
}) => ({
  translate: getTranslate(localize),
  activeLanguage: getActiveLanguage(localize),
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Activities)));
