import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const CodeEntryDialog = ({
  titleId, textId, open, handleClose, handleTextInputChange,
  textInputValue, textInputError, history,
}) => (
  <div>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          handleClose();
        }
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Translate id={titleId} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id={textId} />
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="codeEntry"
          label={<Translate id="codeEntry.inputLabel" />}
          type="text"
          onChange={handleTextInputChange}
          value={textInputValue}
          error={textInputError}
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            /* const path = history.location.pathname.split('/');
            const s = path.slice(0, path.length - 1).join('/');
            history.replace(s); */
            history.push('/e');
          }}
        >
          <Translate id="core.cancel" />
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

CodeEntryDialog.propTypes = {
  titleId: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleTextInputChange: PropTypes.func.isRequired,
  textInputValue: PropTypes.string.isRequired,
  textInputError: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CodeEntryDialog);
