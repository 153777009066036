import React from 'react';
import { Typography } from '@material-ui/core';
import Activities from './activities';

const Teacher = () => (
  <div>
    <Typography variant="h4">
      {/* <Translate id="" /> */}
Aktiviteter
    </Typography>
    <Activities />
  </div>
);

export default Teacher;
