import React from 'react';
import { Select, MenuItem, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getLanguages, getActiveLanguage, setActiveLanguage,
} from 'react-localize-redux';
import PropTypes from 'prop-types';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';

const LanguageSelector = ({
  classes, languages, activeLanguage, setLang,
}) => (
  <div className={classes.root}>
    <LanguageIcon className={classes.icon} />
    {activeLanguage && (
      <Select
        onChange={(event) => {
          document.documentElement.lang = event.target.value;
          localStorage.setItem('language', event.target.value);
          setLang(event.target.value);
        }}
        value={activeLanguage.code}
      >
        {
          languages.map(lang => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))
        }
      </Select>
    )}
  </div>
);


LanguageSelector.propTypes = {
  languages: PropTypes.array.isRequired,
  activeLanguage: PropTypes.object,
  setLang: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

LanguageSelector.defaultProps = {
  activeLanguage: undefined,
};

const mapStateToProps = ({ localize }) => ({
  languages: getLanguages(localize),
  activeLanguage: getActiveLanguage(localize),
});

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: { marginRight: '0.5rem' },
});

export default compose(
  connect(mapStateToProps, { setLang: setActiveLanguage }),
  withStyles(styles),
)(LanguageSelector);
