import 'typeface-pt-sans';
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  containerMarginBase: marginToAdd => `calc(100vw - 100% + ${marginToAdd || '0px'})`,
  palette: {
    primary: {
      light: '#80add3',
      main: '#005aa7',
      dark: '#002d53',
    },
    secondary: {
      main: '#F2F2F2',
    },
    error: {
      main: '#e41f1a',
    },
    background: {
      default: '#f2f2f2',
    },
  },
  typography: {
    fontFamily: 'PT Sans',
    useNextVariants: true,
  },
  overrides: {
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});

export default theme;
