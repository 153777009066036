import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

const Textbox = ({
  classes, title, className, children, linktext, link,
}) => (
  <div className={clsx(className)}>
    <div className={classes.container}>
      <Typography variant="h6">{title}</Typography>
      {children}
      {linktext && <Button href={`#${link}`} size="small" className={classes.button}>{linktext}</Button>}
    </div>
  </div>
);

Textbox.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.any,
  children: PropTypes.any,
  linktext: PropTypes.any,
  link: PropTypes.any,
  className: PropTypes.string,
};

Textbox.defaultProps = {
  title: '',
  children: '',
  linktext: '',
  link: '',
  className: undefined,
};

const styles = theme => ({
  container: {
    padding: '1.3rem',
    border: '0.05rem solid #cccccc',
  },
  button: {
    marginLeft: '-0.4rem',
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(Textbox);
