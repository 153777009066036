import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const urlPattern = /^https?:\/\//;
function urlIsExternal(urlString) {
  return urlPattern.test(urlString);
}

const SimpleCard = ({
  classes, history, icon: Icon,
  title, flavour, href,
}) => (
  <Card className={classes.card}>
    <CardActionArea
      onClick={urlIsExternal(href) ? () => { window.open(href, '_blank').opener = null; } : () => history.push(href || './')}
      className={classes.cardaction}
    >
      <CardContent className={classes.cardaction}>
        <div className={classes.titleIcon}>
          {Icon ? <Icon color="primary" className={classes.icon} /> : <div className={classes.noIcon} />}
        </div>
        <div className={classes.textContainer}>
          <Typography variant="h6" color="primary" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" color="textPrimary">
            {flavour}
          </Typography>
        </div>
        <Hidden xsDown>
          <KeyboardArrowRight className={classes.arrowIcon} />
        </Hidden>
      </CardContent>
    </CardActionArea>
  </Card>
);

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.func,
  title: PropTypes.any.isRequired,
  href: PropTypes.string,
  flavour: PropTypes.any,
  history: PropTypes.object.isRequired,
};

SimpleCard.defaultProps = {
  flavour: '',
  href: '',
  icon: null,
};

const styles = theme => ({
  textContainer: {
    paddingLeft: '1.3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    width: '3rem',
    height: '3rem',
  },
  cardaction: {
    padding: 0,
    height: '100%',
    width: '100%',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'stretch',
  },
  titleIcon: {
    backgroundColor: '#DDE3ED',
    minHeight: '5rem',
    minWidth: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowIcon: {
    height: '3rem',
    marginLeft: 'auto',
    width: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

export default withRouter(withStyles(styles)(SimpleCard));
