import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, getTranslate } from 'react-localize-redux';
import { withStyles, Typography, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Skeleton from 'react-loading-skeleton';
import { Fetch } from '../../services/fetch-service';

class Teacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participationId: undefined,
      fetchError: undefined,
    };

    const { match: { params: { activityId } } } = props;
    Fetch(`/flyvo-auth/generate/participationId?activityId=${activityId}`)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(({ participationId }) => {
        this.setState({ participationId });
        return participationId;
      })
      .catch(() => this.setState({ fetchError: true }));
  }

  render() {
    const {
      classes, history, match: { params: { name } }, translate,
    } = this.props;
    const { participationId, fetchError } = this.state;
    return (
      <>
        <Typography variant="h4">{decodeURIComponent(name)}</Typography>
        <div className={classes.wrapper}>
          {!fetchError && (participationId ? (
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h2" component="p">
                {`${translate('qrCode.code')}: ${participationId}`}
              </Typography>
              <div className={classes.flex}>
                <img style={{ width: 'calc(80% - 4rem)', height: 'calc(80% - 4rem)' }} className={classes.img} alt="QR kode" src={`/flyvo-auth/generate/qr?participationId=${participationId}`} />
              </div>
            </Paper>
          ) : (
            <Skeleton height="8rem" />
            /* <Typography variant="body1"><Translate id="qrCode.generating" /></Typography> */
          ))}
        </div>
        {fetchError && (
          <Typography variant="body1" className={classes.margin}><Translate id="qrCode.fetchError" /></Typography>
        )}
        <Button
          variant="outlined"
          color="primary"
          className={classes.backbtn}
          onClick={() => {
            const path = history.location.pathname.split('/');
            const s = path.slice(0, path.length - 1).join('/');
            history.replace(s);
          }}
        >
          <ArrowBack />
          <Translate id="core.back" />
        </Button>
      </>
    );
  }
}

Teacher.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};
const styles = () => ({
  wrapper: {
    maxHeight: '90vh',
  },
  img: {
    imageRendering: 'crisp-edges',
    margin: '2rem 0',
  },
  paper: {
    padding: '1rem',
    marginTop: '1rem',
    border: '1px solid lightgray',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
  },
  margin: {
    margin: '1rem 0',
  },
  backbtn: {
    marginTop: '3rem',
  },
});

const mapStateToProps = ({ localize }) => ({ translate: getTranslate(localize) });

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Teacher)));
