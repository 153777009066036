import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Fetch } from '../services/fetch-service';

class PageContainer extends Component {
  componentDidMount() {
    Fetch('/flyvo-auth/loggedIn');
  }

  render() {
    const { children, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {children}
        </div>
      </div>
    );
  }
}

PageContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
};

PageContainer.defaultProps = {
  children: undefined,
};

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      flex: '1 0 0',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '0.93 0 0',
    },
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      padding: '0 1rem',
      marginLeft: theme.containerMarginBase(),
      marginTop: '1rem',
    },
    minWidth: '20rem',
  },
  wrapper: {
    maxWidth: '68.5rem',
    margin: 'auto',
    padding: '1.6rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.6rem',
      paddingBottom: '1.6rem',
    },
  },
});

export default withStyles(styles)(PageContainer);
