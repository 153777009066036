import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
// import { School, Person } from '@material-ui/icons';
// import { Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Fetch } from '../../services/fetch-service';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTeacher: false,
      isStudent: false,
    };
  }

  componentDidMount() {
    Fetch('/flyvo-auth/loggedIn')
      .then((res) => {
        if (res.status === 200) {
          Fetch('/flyvo-auth/isTeacher')
            .then((teacherres) => {
              if (teacherres.status === 200) {
                this.setState({ isTeacher: true, isStudent: false });
              } else if (teacherres.status === 204) {
                this.setState({ isTeacher: false, isStudent: true });
              }
            });
        }
      });
  }

  render() {
    const { classes, history } = this.props;
    const { isTeacher, isStudent } = this.state;
    if (isStudent) {
      history.replace('/e');
    } else if (isTeacher) {
      history.replace('/l');
    }
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          {!isStudent && !isTeacher && (
            <Grid item xs={12} sm={6} styles={{ flexGrow: 0 }}>
              <Typography gutterBottom variant="h6" className={classes.title}>
        Logg inn med TROVO-kontoen din
              </Typography>
            </Grid>
          )}
          {/* {isStudent
          && (
            <Grid item xs={12} sm={6} styles={{ flexGrow: 0 }}>
              <Card
                title={<Translate id="landing.student.title" />}
                href="/e"
                flavour={<Translate id="landing.student.body" />}
                icon={School}
              />
            </Grid>
          )
          }
          {isTeacher
            && (
              <Grid item xs={12} sm={6} styles={{ flexGrow: 0 }}>
                <Card
                  title={<Translate id="landing.teacher.title" />}
                  href="/l"
                  flavour={<Translate id="landing.teacher.body" />}
                  icon={Person}
                />
              </Grid>
            )
          } */}
        </Grid>
      </div>
    );
  }
}
Landing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const styles = () => ({
  root: {
  },
  title: {
    lineHeight: '1.4rem',
  },
});

export default withStyles(styles)(withRouter(Landing));
