// eslint-disable-next-line import/prefer-default-export
export function Fetch(url, params) {
  let fetchParams = params;
  if (!fetchParams) {
    fetchParams = { headers: {} };
  }
  fetchParams = {
    ...fetchParams,
    headers: {
      ...fetchParams.headers,
    },
  };

  return fetch(url, fetchParams).then((res) => {
    if (res.status === 401) {
      const { origin, pathname, search } = window.location;
      const href = `${origin}/flyvo-auth/redirect?redirect-uri=${pathname}${search}`;
      window.location.href = href;
    }

    return res;
  });
}
