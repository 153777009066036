import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  withStyles, Typography, Paper, SvgIcon,
} from '@material-ui/core';
import {
  getDateTime,
} from '@tktip/components';
// TODO: fix getActiveLanguage usage
// eslint-disable-next-line no-unused-vars
import { Translate, getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Skeleton from 'react-loading-skeleton';
import Accessibility from '@material-ui/icons/Accessibility';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Autorenew, ErrorOutline } from '@material-ui/icons';
import { Fetch } from '../../services/fetch-service';
import Textbox from '../../components/text-box';

// mdAdmin
// Passord1!
class AbsenceOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numSelfCertifications: 0,
      sumSelfCertificationsChildren: 0,
      fetched: false,
      fetchError: undefined,
    };

    // this.fetchActivities();
  }

  componentDidMount() {
    this.fetchActivities();
  }

  fetchActivities = () => {
    const { activeLanguage } = this.props;
    const todayDate = new Date();
    this.setState({ fetched: false });

    Fetch(`/flyvo-auth/absence/getSickleaves/${getDateTime(todayDate, 'P', activeLanguage)}`)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        this.setState({
          sumSelfCertificationsChildren: res.sumSelfCertificationsChildren,
          numSelfCertifications: res.numSelfCertifications,
          fetched: true,
        });
      })
      // .then(({ activities }) => this.setState({ activities, fetched: true }))
      .catch(() => this.setState({ fetchError: true, fetched: true }));
  }

  render() {
    const { classes, history, translate } = this.props;
    const {
      numSelfCertifications, sumSelfCertificationsChildren, fetched, fetchError,
    } = this.state;
    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.header}><Translate id="student.absenceOverview.headline" /></Typography>
        {!fetched ? (
          <Paper elevation={0} className={classes.paper}>
            <Skeleton height="1rem" count={2} />
          </Paper>
        ) : (
          <>
            {!fetchError ? (
              <>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" color="primary">
                    <Accessibility className={classes.avatar} />
                    {`${translate('student.absenceOverview.selfCertification')}: ${numSelfCertifications}`}
                  </Typography>
                </Paper>
                <Paper elevation={0} className={clsx(classes.paper, classes.mt)}>
                  <Typography variant="h6" color="primary">
                    <SvgIcon className={classes.avatar}>
                      <path d="M6.5,2c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S5.4,2,6.5,2z M15.5,9.5c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5 S17.83,8,17,8S15.5,8.67,15.5,9.5z M18.5,12h-2.84c-0.58,0.01-1.14,0.32-1.45,0.86l-0.92,1.32L9.72,8C9.35,7.37,8.69,7.01,8.01,7H5 C3.9,7,3,7.9,3,9v6h1.5v7h5V11.61L12.03,16h2.2L15,14.9V22h4v-5h1v-3.5C20,12.68,19.33,12,18.5,12z" />
                    </SvgIcon>
                    {`${translate('student.absenceOverview.selfCertificationChildren')}: ${sumSelfCertificationsChildren}`}
                  </Typography>
                </Paper>
              </>
            ) : (
              <Textbox title={(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutline color="error" style={{ marginRight: '1rem' }} />
                  <Translate id="student.absenceOverview.fetchErrorTitle" />
                </div>
              )}
              >
                <Typography variant="body1">
                  <Translate id="student.absenceOverview.fetchError" />
                </Typography>
                <div className={classes.retrybtn}>
                  <Button color="primary" variant="outlined" onClick={this.fetchActivities}>
                    <Translate id="core.retry" />
                    <Autorenew />
                  </Button>
                </div>
              </Textbox>
            )}
          </>
        )}

        <Button
          variant="outlined"
          color="primary"
          className={classes.backbtn}
          onClick={() => {
            const path = history.location.pathname.split('/');
            const s = path.slice(0, path.length - 1).join('/');
            history.replace(s);
          }}
        >
          <ArrowBack />
          <Translate id="core.back" />
        </Button>
      </div>
    );
  }
}

AbsenceOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  activeLanguage: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
const styles = () => ({
  header: {
    marginBottom: '1rem',
  },
  dateRangePickerLabel: {
    margin: '0.5rem',
  },
  drpContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  drp: {
    minWidth: '20rem',
    flexGrow: '1',
  },
  backbtn: {
    marginTop: '3rem',
  },
  paper: {
    padding: '1rem',
    border: '1px solid lightgray',
  },
  mt: {
    marginTop: '1rem',
  },
  avatar: {
    marginRight: '0.5rem',
  },
  retrybtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '1rem',
  },
});

const mapStateToProps = ({
  localize,
}) => ({
  translate: getTranslate(localize),
  activeLanguage: 'nb', // getActiveLanguage(localize).code,
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(AbsenceOverview)));
