import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  withStyles, Typography,
} from '@material-ui/core';
// TODO: fix getActiveLanguage usage
// eslint-disable-next-line no-unused-vars
import { Translate, getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Autorenew, ErrorOutline, CheckCircleOutline } from '@material-ui/icons';
import { Fetch } from '../services/fetch-service';
import CodeEntryDialog from './code-entry-dialog';
import Textbox from './text-box';


class RegistrationTemplate extends Component {
  constructor(props) {
    super(props);
    const { match: { params: { code } } } = props;

    this.state = {
      success: false,
      fetching: false,
      fetched: false,
      duplicate: false,
      openDialog: !code,
      code: code || '',
      textInputError: false,
      notExist: false,
    };

    if (code) {
      this.state.fetching = true;
      this.register();
    }
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const a = new URLSearchParams(search).get('id');
    if (a) {
      this.setState({
        code: a, openDialog: false, fetching: true, duplicate: false,
      });
      const { registrationType } = this.props;
      Fetch(`/flyvo-auth/event/${registrationType}?participationId=${a}`, { redirect: 'manual' })
        .then((res) => {
          if (!res.ok) {
            if (res.status === 400) {
              this.setState({ duplicate: true });
            }
            throw res;
          }
          this.setState({ success: true });
          return {};
        })
        .then(data => this.setState({ notExist: data.code === 0 }))
        .catch(() => {
          this.setState({ success: false });
        })
        .finally(() => this.setState({ fetched: true, fetching: false }));
    }
  }

  handleTextInputChange = e => this.setState({ code: e.target.value, textInputError: false })

  handleClose = () => {
    const { code, openDialog } = this.state;
    if (code && openDialog) {
      this.setState({ openDialog: false, fetching: true, duplicate: false });
      this.register();
      return;
    }
    if (openDialog) {
      this.setState({ textInputError: true });
    }
  }

  register = () => {
    const { code } = this.state;
    const { registrationType } = this.props;
    Fetch(`/flyvo-auth/event/${registrationType}?participationId=${code}`, { redirect: 'manual' })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            this.setState({ duplicate: true });
            throw res;
          }
          return res.json();
        }
        this.setState({ success: true });
        return {};
      })
      .then(data => this.setState({ notExist: data.code === 0 }))
      .catch(() => {
        this.setState({ success: false });
      })
      .finally(() => this.setState({ fetched: true, fetching: false }));
  }

  render() {
    const {
      classes, registrationType, history,
    } = this.props;
    const {
      success, fetching, fetched, openDialog, textInputError, code, duplicate, notExist,
    } = this.state;

    return (
      <div className={classes.root}>
        {openDialog && (
          <CodeEntryDialog
            titleId={`student.${registrationType}.dialog.title`}
            textId={`student.${registrationType}.dialog.text`}
            open={openDialog}
            handleClose={this.handleClose}
            handleTextInputChange={this.handleTextInputChange}
            textInputValue={code}
            textInputError={textInputError}
          />
        )}

        {!openDialog && (
          <Typography variant="h4" className={classes.header}><Translate id={`student.${registrationType}.dialog.title`} /></Typography>
        )}

        {!openDialog && (fetching ? (
          <Textbox><Skeleton height="1rem" /></Textbox>
        ) : (
          fetched && (
            <Textbox title={!duplicate ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!success ? [
                  <ErrorOutline key="icon" color="error" style={{ marginRight: '1rem' }} />,
                  <Translate key="text" id={`student.${registrationType}.fetchErrorTitle`} />,
                ] : [
                  <CheckCircleOutline key="icon" style={{ marginRight: '1rem', color: 'green' }} />,
                  <Translate key="text" id={`student.${registrationType}.fetchSuccessTitle`} />,
                ]}
              </div>
            ) : ''}
            >
              {success ? (
                <Typography variant="body1"><Translate id={`student.${registrationType}.success`} data={{ code }} /></Typography>
              ) : [
                duplicate ? (
                  <Typography variant="body1" key="duplicate">
                    <Translate id={`student.${registrationType}.duplicate`} />
                  </Typography>
                ) : [
                  <Typography variant="body1" key="text">
                    <Translate id={`student.${registrationType}.${notExist ? 'notexists' : 'fetchError'}`} data={{ code }} />
                  </Typography>,
                  <div className={classes.retrybtn} key="btn">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        this.setState({ fetching: true });
                        if (notExist) {
                          this.setState({ openDialog: true });
                        } else this.register();
                      }}
                    >
                      <Translate id={`student.${registrationType}.retry`} />
                      <Autorenew />
                    </Button>
                  </div>,
                ],
              ]}
            </Textbox>
          )
        ))}
        {!openDialog && fetched && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.backbtn}
            onClick={() => {
              history.push('/e');
            }}
          >
            <ArrowBack />
            <Translate id="core.back" />
          </Button>
        )}
      </div>
    );
  }
}

RegistrationTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  registrationType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
const styles = () => ({
  dateRangePickerLabel: {
    margin: '0.5rem',
  },
  drpContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  backbtn: {
    marginTop: '3rem',
  },
  header: {
    marginBottom: '1rem',
  },
  retrybtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '1rem',
  },
});

const mapStateToProps = ({
  localize,
}) => ({
  translate: getTranslate(localize),
  activeLanguage: 'nb', // getActiveLanguage(localize).code, // TODO FIXME
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withRouter,
)(RegistrationTemplate);
